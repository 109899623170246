import {Component, OnInit, AfterViewInit, ViewChild, TemplateRef} from '@angular/core';
import {Order, Orders} from '../../../shared/classes/order';
import {OrderService} from '../../../shared/services/order.service';
import {ProductService} from '../../../shared/services/product.service';
import {HttpService} from '../../../services/http.service';
import {AuthService} from '../../../shared/services/auth.service';
import {User} from '../../../shared/classes/user';
import {AppNotifications} from '../../../shared/classes/mail';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, AfterViewInit {
    @ViewChild('content2') content2: TemplateRef<any>;
    public orderDetails = {
        shippingDetails: null,
        items: [],
        orderId: null,
        totalAmount: null,
        total: null,
        subtotal: 0,
        shipping_cost: 0,
        tax: 0,
        service_fee: 0,
        created_at_formatted: null,
        waytopay: null,
        used_credits: 0,
    };
    items: any [] = [];
    order = new Orders();
    isLogged: boolean = false;
    rawOrder: any [] = [];

    user = new User();
    txtSuccess: string = '<p>Se ha recibido el pago del Pedido con éxito</p><p>Transacción ID: $order_uid</p>';

    constructor(
        public productService: ProductService,
        private orderService: OrderService,
        private auth: AuthService,
        private service: HttpService,
    ) {
        let txtSuccess = localStorage.getItem('txtSuccess') || '';
        if (txtSuccess != null || txtSuccess != undefined) {
            this.txtSuccess = txtSuccess;
        }
        this.auth.getUser2().then(async userLocal => {
            this.isLogged = true;
            console.log('userLocal: ', userLocal);
            this.user = userLocal;
            this.getOneOrder();
        }).catch(e => {

        });
    }

    ngOnInit(): void {
        console.log('this.orderDetails', this.orderDetails);
    }

    ngAfterViewInit() {

    }

    getOneOrder() {
        let order_uid = localStorage.getItem('order_uid');
        console.log('order_uid', order_uid);
        if (order_uid != '') {
            localStorage.removeItem('order_uid');
            this.service.getOneOrder(order_uid).subscribe(res => {
                if (res.user_uid == this.user.uid) {
                    this.orderDetails.orderId = res.uid;
                    this.orderDetails.items = res.items;
                    this.orderDetails.totalAmount = res.total;
                    this.orderDetails.total = res.total;
                    this.orderDetails.subtotal = res.amount || 0;
                    this.orderDetails.shipping_cost = res.shipping_cost;
                    this.orderDetails.tax = res.tax;
                    this.orderDetails.service_fee = res.service_fee;
                    this.orderDetails.used_credits = res.used_credits;
                    this.orderDetails.created_at_formatted = res.created_at_formatted;
                    this.orderDetails.waytopay = res.waytopay;
                    console.log('response getOneOrder', res);
                    let message = res;
                    let finalStatusOrder = localStorage.getItem('finalStatusOrder');
                    let finalBadgeColor = localStorage.getItem('finalBadgeColor');
                    message.orders_status_uid = finalStatusOrder || 'New';
                    message.badgeColor = finalBadgeColor || 'primary';
                    this.service.updateOrder(message, message.id).then(res => {
                        Swal.close();
                        Swal.fire('Exito', 'Pedido Procesado correctamente', 'success');
                        console.info('update order', res);
                        localStorage.removeItem('mainBadgeColor');
                        localStorage.removeItem('mainStatusOrder');
                        localStorage.removeItem('finalStatusOrder');
                        localStorage.removeItem('finalBadgeColor');
                        localStorage.removeItem('txtSuccess');
                    }).catch(err => {
                        console.error('error update order', err);
                    });

                    let mail: AppNotifications = {
                        uid_mail_option: 'yRyi3kM7Lcv0CO8sjFRi',
                        uid_notification_template: 'nVLciDaxW6aoblmrczyO',
                        recipient: this.user.email,
                        data: {
                            uid_order: res.uid,
                            commerce_name: res.sucursal || res.commerce_name,
                            commerce_direction: res.commerce_direction,
                        }
                    };


                    /*let templateContent;
                    // templateContent = document.getElementById("table-invoice").innerHTML;
                    templateContent = document.querySelector('#table-invoice') as HTMLElement | null;
                    console.log('templateContent', templateContent);*/

                    let templateContent = localStorage.getItem('templateContent');
                    let push2Options = {
                        uid_mail_option: 'yRyi3kM7Lcv0CO8sjFRi',
                        uid_notification_template: 'eavSm07EOrq39Cvhcfqn',
                        recipient: this.user.email,
                        data: {
                            url_logo: res.commerce_image,
                            contentItems: templateContent,
                            subtotalInvoice: '$ ' + this.ToFixed(res.amount),
                            tipInvoice: '$ ' + this.ToFixed(res.tip_amount),
                            tax: '$ ' + this.ToFixed(res.tax),
                            deliveryInvoice: '$ ' + this.ToFixed(res.subtotalForRide || 0.00),
                            totalInvoice: '$ ' + this.ToFixed(res.total),
                            nameCustomer: res.who_receives,
                            uid: res.uid,
                            created_at_formatted: res.created_at_formatted,
                            is_card: res.waytopay,
                            emailCustomer: this.user.email,
                            addressCustomer: res.direction || '',
                            commerce_direction: res.commerce_direction || '',
                            commerce_name: res.commerce_name
                        }
                    };

                    this.service.sendEmail(push2Options).then((res) => {
                        console.log('send Email!!', res);
                        localStorage.removeItem('templateContent');
                    });
                    console.info('update order', res);
                } else {
                    // window.location.href = '/home';
                }
            });
        }
    }

    ToFixed(data: number) {
        return Number(data).toFixed(2);
    }

}


