<app-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>create account</h3>
                <div class="theme-card">
                    <form class="theme-form" autocomplete="off">
                        <!--<div class="row">
                            <div class="col-md-6">
                                <label for="email">First Name</label>
                                <input type="text" class="form-control" id="fname" placeholder="First Name" required="">
                            </div>
                            <div class="col-md-6">
                                <label for="review">Last Name</label>
                                <input type="password" class="form-control" id="lname" placeholder="Last Name" required="">
                            </div>
                        </div>-->
                        <div class="row check-out">
                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                <div class="field-label">Correo electrónico</div>
                                <input type="text" name="email" value="" placeholder="" [formControl]="checkoutForm.controls['email']"  class="form-control" autocomplete="off">
                                <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.required" class="text text-danger">
                                    Correo electrónico obligatorio
                                </div>
                                <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.email" class="text text-danger">
                                    Correo electrónico no válido
                                </div>
                            </div>

                            <div class="form-group col-md-6 col-sm-6 col-xs-6">
                                <div class="field-label">Contraseña</div>
                                <input type="password" name="password" class="form-control" [formControl]="checkoutForm.controls['password']" value="" placeholder="" autocomplete="off">
                                <div *ngIf="checkoutForm.controls.password.touched && checkoutForm.controls.password.errors?.required" class="text text-danger">
                                    Contraseña es requerida
                                </div>
                                <div *ngIf="checkoutForm.controls.password.touched && checkoutForm.controls.password.errors?.minlength" class="text text-danger">
                                    Mínimo 6 caracteres
                                </div>
                                <div *ngIf="checkoutForm.controls.password.touched && checkoutForm.controls.password.errors?.pattern" class="text text-danger">
                                    La contraseña debe contener al menos una letra mayúscula, un número y un carácter especial.
                                </div>
                            </div>
                        </div>
                        <button [disabled]="!checkoutForm.valid" (click)="createAccount()" class="btn btn-solid">create Account</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->