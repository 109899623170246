import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { HttpService } from "../../services/http.service";
import { environment } from "../../../environments/environment";
import { isPlatformBrowser, ViewportScroller } from "@angular/common";
import { ProductService } from "../../shared/services/product.service";
import { FirestoreService } from "../../services/firestore.service";
import { Options } from "ng5-slider";
import Swal from "sweetalert2";
import Fuse from 'fuse.js';
import { Subscription } from "rxjs";


@Component({
    selector: 'app-resultado',
    templateUrl: './resultado.component.html',
    styleUrls: ['./resultado.component.scss']
})

export class ResultadoComponent {
    public urlCategory: string = '';
    public urlSubcategory: string = '';

    public grid: string = 'col-xl-3 col-md-6';
    public layoutView: string = 'grid-view';
    public all_products: any[] = [];
    public products: any[] = [];
    public brands: any[] = [];
    public colors: any[] = [];
    public size: any[] = [];
    public minPrice: number = 0;
    public maxPrice: number = 1;
    public minDE: number = 0;
    public maxDE: number = 0;
    public minDI: number = 0;
    public maxDI: number = 0;
    public minL: number = 0;
    public maxL: number = 0;
    public tags: any[] = [];
    public category: string;
    public pageNo: number = 1;
    public paginate: any = {}; // Pagination use only
    public sortBy: string; // Sorting Order
    public mobileSidebar: boolean = false;
    public loader: boolean = true;
    public finished: boolean = false  // boolean when end of data is reached
    public addItemCount = 8;
    public url: string = '';
    public urlID: string = '';
    public loadGif: string = environment.loadGif;
    public showLoading: boolean = true;
    public infoSubcategory = {
        "id": '',
        "bannerSubcategory": environment.bannerDefault || 'assets/images/collection-banner.jpg',
        "nameSubcategory": '',
        "subcategory_description": 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        "subcategory_highlights_description": 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and\n' +
            'scrambled it to make a type specimen book. It has\n' +
            'survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum\n' +
            'passages,\n' +
            'and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    };
    public data = {
        'type': 2,
        'url': '',
        'offset': 1,
        'limit': 100,
        'page': 1,
    };
    public sendBreadcrumbDynamic: any[] = [];
    public categories: any[] = [];
    public allCategories: any[] = [];
    public subcategories: any[] = [];
    public classifications: any[] = [];
    public optionsDE: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsDI: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsL: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsPrice: Options = {
        floor: 0,
        ceil: 1e3,
    };
    public collapse: boolean = true;
    public collapse_sizes: boolean = true;
    public collapse_colors: boolean = true;
    public collapse_conditions: boolean = true;
    public isBrowser: boolean = false;
    public loading: boolean = false;
    public search: string = '';
    productsSearch: any[] = [];
    resultSearch: any[] = [];
    userSearch: boolean = false;
    private routerSubscription: Subscription;
    private new_promote = false;
    private new_discount = 0;
    private limit_year = 0;
    private limit_week = 0;
    private loteProducts = 0;
    private discount_after: 0;
    private discount_after_limit: false;
    public sizesAndColors: any = {};
    public sizesSelected = [];
    public conditionsSelected = [];
    public colorsSelected = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private route: ActivatedRoute,
        private router: Router,
        private service: HttpService,
        private viewScroller: ViewportScroller,
        public productService: ProductService,
        private firestore: FirestoreService
    ) {
        if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
        this.routerSubscription = this.router.events.subscribe(event => {
            this.search = this.route.snapshot.paramMap.get('search');
            this.url = this.router.url;
            // console.log('search', this.search);
            // console.log('url', this.url);
            if (event instanceof NavigationEnd) {
                Swal.fire({
                    icon: 'info',
                    title: 'Buscando producto, por favor espere un momento',
                    showConfirmButton: false,
                    timer: 2e3
                });
                if (this.url.startsWith('/pages/resultado')) {
                    this.getProductsForSearch();
                }
            }
        })

        this.getProductsForSearch();
        this.getSettingsSizesAndColor();

        // let arrayBreadcrumb = this.url.split('/');
        // this.sendBreadcrumbDynamic = environment.breadcrumbDynamic(arrayBreadcrumb);

        /*this.service.getSubcategoryByUrl(this.urlSubcategory).subscribe(res => {
            console.log('getSubcategoryByUrl', res);

            this.classifications = res.classifications;
            this.infoSubcategory.id = res.subcategory.id;
            this.infoSubcategory.nameSubcategory = res.subcategory.name;
            console.log('infoSubcategory.id', this.infoSubcategory.id);


            // this.firestore.getWhere('Products','subcategory_uid',this.infoSubcategory.id).subscribe( response => {
            this.data.url = this.urlSubcategory;
            this.service.getProductsByUrl(this.data).subscribe(response => {
                this.all_products = [];
                console.log('response products by subcategory', response)
                // console.table(response);
                this.addProducts(response);
                this.showLoading = false;
                this.addItems(666);
            });
        })

        this.service.getCategoryByUrl(this.urlCategory).subscribe((res) => {
            res.subcategories.forEach(element => {
                element.url = this.urlCategory + '/' + element.url;
                this.subcategories.push(element);
            })
        });
        ;*/
        this.service.getCategories().subscribe((res) => {
            res.forEach(element => {
                if (element.category_active && element.category_disabled) {
                    element.name = element.category_name;
                    element.url = element.url_category;
                    this.allCategories.push(element);
                }
            })
        })
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        // Cancelar la suscripción cuando el componente se destruye
        this.routerSubscription.unsubscribe();
    }

    getSettingsSizesAndColor() {
        this.firestore.getOneWatcher('Settings', 'SizesAndColors').subscribe(res => {
            if (resizeBy) {
                this.sizesAndColors = res;
            }
        })
    }

    filter(event) {
        event = event.replaceAll('-', ' ');

        // console.log('search by', event)
        event = event.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const options = {
            includeScore: true,
            keys: ['product_name'],
            shouldSort: true,
            // minMatchCharLength: 4,
            threshold: 0.4
        };

        const fuse = new Fuse(this.productsSearch, options);

        const results = fuse.search(event);
        this.resultSearch = results.map(result => result.item);
        // this.resultSearch = this.productsSearch.filter(
        //     item => item.product_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(event.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) > -1);
        this.resultSearch = results.map(result => result.item);
        this.resultSearch.forEach(element => {
            if (element.diametro_interno != undefined && element.diametro_interno > this.maxDI) this.maxDI = element.diametro_interno;
            if (element.diametro_externo != undefined && +element.diametro_externo > this.maxDE) this.maxDE = +element.diametro_externo;
            if (element.alto != undefined && element.alto > this.maxL) this.maxL = element.alto;
            if (element.price != undefined && element.price > this.maxPrice) this.maxPrice = Math.round(element.price);
        })
        this.all_products = this.resultSearch;
        // console.log('this.all_products',this.all_products)
        this.filterbyCategories(this.all_products);
        this.addItems();
        // Swal.fire({
        //     icon: 'success',
        //     title: 'Búsqueda con éxito',
        //     showConfirmButton: false,
        //     timer: 1e3
        // });
        this.userSearch = true;
        this.showLoading = false;
        // console.log('result filter', this.resultSearch)
    }

    async getProductsForSearch() {
        let parentCollection = 'Settings'
        this.showLoading = true;

        await this.firestore.getOne(parentCollection, 'Promotions').subscribe(async res => {
            if (res['active']) {
                this.new_promote = true;
                let uid_promotion_active = res['uid_promotion_active'];
                let documentPath = parentCollection + '/Promotions/list/' + uid_promotion_active;

                await this.firestore.getDocument(documentPath).subscribe(res1 => {
                    if (res1) {
                        // Verificar y asignar un valor predeterminado de 0 si es undefined
                        this.new_discount = res1['discount'] !== undefined ? res1['discount'] : false;
                        this.loteProducts = res1['loteProducts'] !== undefined ? res1['loteProducts'] : 0;
                        this.limit_year = res1['limit_year'] !== undefined ? res1['limit_year'] : 0;
                        this.limit_week = res1['limit_week'] !== undefined ? res1['limit_week'] : 0;
                        this.discount_after = res1['discount_after'] !== undefined ? res1['discount_after'] : 0; // Leo | 2023-11-23
                        this.discount_after_limit = res1['discount_after_limit'] !== undefined ? res1['discount_after_limit'] : false; // Leo | 2023-11-23

                        this.getProducts();
                    } else {
                        this.new_promote = false;
                        this.getProducts();
                    }
                })
            } else {
                this.new_promote = false;
                this.getProducts();
            }
        });
    }

    private getProducts() {
        this.firestore.getWhere('Products', 'product_active', true).subscribe(res => {
            this.productsSearch = [];
            res.forEach(element => {
                if (element.product_available && element.product_active && element.products_stock > 0 && element.price_tax_product > 0) {
                    let img = {
                        "src": '',
                        "alt": element.product_name,
                    }
                    element.title = element.product_name;
                    element.description = element.product_description || element.specifications;
                    element.brand = element.marca;
                    element.sale = element.product_active;
                    element.price = element.price_tax_product;
                    element.new = false;
                    // element.discount = (this.new_promote) ? this.new_discount : element.product_discount || element.discount;
                    element.discount = element.product_discount || element.discount;


                    // console.log('product_createday["_seconds"] '+element.product_createday+' - '+element.id);

                    const fechaString = element.doc_created_date;
                    const fecha = new Date(fechaString);

                    // Obtener el año
                    const year = fecha.getFullYear();

                    // Obtener el número de semana
                    const numeroSemana = this.getWeekNumber(fecha);

                    const currentDate = new Date();
                    const currentYear = currentDate.getFullYear();

                    if (this.new_promote) {
                        if (year == currentYear) {
                            if (numeroSemana <= this.loteProducts) {
                                element.discount = this.new_discount;
                            } else {
                                if (this.discount_after_limit) {
                                    if (numeroSemana <= this.limit_week) {
                                      element.discount = this.discount_after
                                    } else {
                                      element.discount = element.product_discount || element.discount;
                                    }
                                  } else {
                                    element.discount = element.product_discount || element.discount;
                                  }
                            }
                        } else {
                            element.discount = this.new_discount;
                        }
                    }

                    let precio = element.price; // Precio original
                    let porcentaje_descuento = element.discount; // Porcentaje de descuento

                    // Calcular el precio con descuento
                    let descuento = (precio * porcentaje_descuento) / 100; // Calcula el valor del descuento
                    // Resta el descuento al precio original
                    element.price_tax_product_discount = precio - descuento;

                    element.stock = element.product_stock || element.products_stock;
                    element.diametro_interno = Number(element.diametro_interno) || 0;
                    element.diametro_externo = Number(element.diametro_externo) || 0;
                    element.alto = Number(element.alto) || 0;
                    element.images = [];
                    if (element.extra_images != null) {
                        element.extra_images.forEach(index => {
                            img.src = index;
                            element.images.push(img);
                        })
                    }
                    img.src = element.product_imagesm;
                    element.images.push(img);
                    this.productsSearch.push(element);
                }
            });
            this.filter(this.search);
        })
    }

    // Función para obtener el número de semana en el año
    getWeekNumber(date) {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7);
        const week1 = new Date(d.getFullYear(), 0, 4);
        // @ts-ignore
        return 1 + Math.round(((d - week1) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    private processProducts(products, new_promote, new_discount) {
        return products
            .filter(element => element.product_available && element.product_active)
            .map(element => {
                const img = {
                    src: '',
                    alt: element.product_name,
                };

                element.title = element.product_name;
                element.description = element.product_description || element.specifications;
                element.brand = element.marca;
                element.sale = element.product_active;
                element.price = element.price_tax_product;
                element.new = false;
                element.discount = (new_promote) ? new_discount : element.product_discount || element.discount;

                const precio = element.price; // Precio original
                const porcentaje_descuento = element.discount; // Porcentaje de descuento

                // Calcular el precio con descuento
                const descuento = (precio * porcentaje_descuento) / 100; // Calcula el valor del descuento
                // Resta el descuento al precio original
                element.price_tax_product_discount = precio - descuento;

                element.stock = element.product_stock || element.products_stock;
                element.diametro_interno = Number(element.diametro_interno) || 0;
                element.diametro_externo = Number(element.diametro_externo) || 0;
                element.alto = Number(element.alto) || 0;
                element.images = [];

                if (element.extra_images != null) {
                    element.extra_images.forEach(index => {
                        img.src = index;
                        element.images.push(img);
                    });
                }

                img.src = element.product_imagesm;
                element.images.push(img);

                return element;
            });
    }


    addItems(type = 0) {
        if (type > 0) {
            // this.finished = true;
            this.products = this.all_products.slice(0, this.addItemCount);
            this.sortByFilter('diametroExterior');
        }
        let newOptionDE: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDE) + ((10 / 100) * Number(this.maxDE))),
        };
        this.optionsDE = newOptionDE;

        let newOptionDI: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDI) + ((10 / 100) * Number(this.maxDI))),
        };
        this.optionsDI = newOptionDI;

        let newOptionL: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxL) + ((10 / 100) * Number(this.maxL))),
        };
        this.optionsL = newOptionL;

        let newOptionPrice: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxPrice) + ((10 / 100) * Number(this.maxPrice))),
        };
        this.optionsPrice = newOptionPrice;
        this.updateFilter();
    }

    // Infinite scroll
    public onScroll() {
        this.addProductsPagination();
    }

    // Append filter value to Url
    updateFilter(tags?: any) {
        console.log("sizesSelected",this.sizesSelected);
        this.resultSearch = this.all_products.filter(item =>
            item.price_tax_product >= this.minPrice && item.price_tax_product <= this.maxPrice &&
            item.diametro_externo >= this.minDE && item.diametro_externo <= this.maxDE &&
            item.diametro_interno >= this.minDI && item.diametro_interno <= this.maxDI &&
            item.alto >= this.minL && item.alto <= this.maxL &&
            (this.sizesSelected.length === 0 || this.sizesSelected.includes(item.product_size_id)) &&
            (this.conditionsSelected.length === 0 || this.conditionsSelected.includes(item.product_condition_id)) &&
            (this.colorsSelected.length === 0 || this.colorsSelected.includes(item.product_color_id)) &&
            (this.brands.length > 0 ? this.brands.indexOf(item.category_uid) > -1 : item.price >= this.minPrice && item.price <= this.maxPrice)
        );
    }

    // SortBy Filter
    sortByFilter(value) {
        this.sortBy = value;
        this.resultSearch = this.productService.sortProducts(this.all_products, this.sortBy);

        /*this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {sortBy: value ? value : null},
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });*/
    }

    // Remove Tag
    removeTag(tag) {

        this.brands = this.brands.filter(val => val !== tag);
        this.colors = this.colors.filter(val => val !== tag);
        this.size = this.size.filter(val => val !== tag);

        let params = {
            brand: this.brands.length ? this.brands.join(",") : null,
            color: this.colors.length ? this.colors.join(",") : null,
            size: this.size.length ? this.size.join(",") : null
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }

    // Clear Tags
    removeAllTags() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }


    // Change Grid Layout
    updateGridLayout(value: string) {
        this.grid = value;
    }

    // Change Layout View
    updateLayoutView(value: string) {
        this.layoutView = value;
        if (value == 'list-view')
            this.grid = 'col-lg-12';
        else
            this.grid = 'col-xl-3 col-md-6';
    }

    // Mobile sidebar
    toggleMobileSidebar() {
        this.mobileSidebar = !this.mobileSidebar;
    }

    priceFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minPrice: event.value, maxPrice: event.highValue };
            console.info('Price Filter', value);
            if (!type) {
                this.minPrice = value.minPrice;
                this.maxPrice = value.maxPrice;
            }
            // Price Filter
            this.resultSearch = this.all_products.filter(item => item.price >= this.minPrice && item.price <= this.maxPrice);
            if (!type) this.sortByFilter('low');
            this.updateFilter();
        }
    }

    sizeFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minPrice: event.value, maxPrice: event.highValue };
            console.info('Price Filter', value);
            if (!type) {
                this.minPrice = value.minPrice;
                this.maxPrice = value.maxPrice;
            }
            // Price Filter
            this.resultSearch = this.all_products.filter(item => item.price >= this.minPrice && item.price <= this.maxPrice);
            if (!type) this.sortByFilter('low');
            this.updateFilter();
        }
    }

    isChecked(name, selectedArray) {
        return selectedArray.includes(name);
    }

    handleSelection(event, selectedArray) {
        const value = event.target.value;
        const index = selectedArray.indexOf(value);

        if (event.target.checked) {
            if (index === -1) selectedArray.push(value); // Agrega el valor si no existe
        } else {
            if (index !== -1) selectedArray.splice(index, 1); // Elimina el valor si existe
        }

        this.updateFilter();
    }

    handleSelectionColor(event, selectedArray) {
        const value = event.target.value;
        const index = selectedArray.indexOf(value);
        selectedArray.length = 0;
        
        if (event.target.checked) {
            if (index === -1) selectedArray.push(value); // Agrega el valor si no existe
        } else {
            if (index !== -1) selectedArray.splice(index, 1); // Elimina el valor si existe
        }

        this.updateFilter();
    }

    diametroExteriorFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minDE: event.value, maxDE: event.highValue };
            console.info('diametroExterior Filter', value);
            if (!type) {
                this.minDE = value.minDE;
                this.maxDE = value.maxDE;
            }
            // diametroExterior Filter
            this.resultSearch = this.all_products.filter(item => item.diametro_externo >= this.minDE && item.diametro_externo <= this.maxDE);
            if (!type) this.sortByFilter('diametroExterior');
            this.updateFilter();
        }
    }

    diametroInteriorFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minDI: event.value, maxDI: event.highValue };
            console.info('diametroInterior Filter', value);
            if (!type) {
                this.minDI = value.minDI;
                this.maxDI = value.maxDI;
            }
            // diametroInterior Filter
            this.resultSearch = this.all_products.filter(item => item.diametro_interno >= this.minDI && item.diametro_interno <= this.maxDI);
            if (!type) this.sortByFilter('diametroInterior');
            this.updateFilter();
        }
    }

    largoFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minL: event.value, maxL: event.highValue };
            console.info('Altura Filter', value);
            if (!type) {
                this.minL = value.minL;
                this.maxL = value.maxL;
            }
            // largo Filter
            this.resultSearch = this.all_products.filter(item => item.alto >= this.minL && item.alto <= this.maxL);
            if (!type) this.sortByFilter('altura');
            this.updateFilter();
        }
    }

    private addProducts(response) {
        this.finished = response.products.length == 0;
        response.products.forEach(element => {
            if (element.product_available && element.product_active && element.products_stock > 0 && element.price_tax_product > 0) {
                let img = {
                    "src": '',
                    "alt": element.product_name,
                }
                element.title = element.product_name;
                element.description = element.specifications;
                element.brand = element.marca;
                element.sale = element.product_active;
                element.discount = element.product_discount || element.discount;
                element.stock = element.products_stock;
                element.price = element.product_price;

                let precio = element.price; // Precio original
                let porcentaje_descuento = element.discount; // Porcentaje de descuento

                // Calcular el precio con descuento
                let descuento = (precio * porcentaje_descuento) / 100; // Calcula el valor del descuento
                // Resta el descuento al precio original
                element.price_tax_product_discount = precio - descuento;
                element.diametro_interno = Number(element.diametro_interno) || 0;
                element.diametro_externo = Number(element.diametro_externo) || 0;
                element.alto = Number(element.alto) || 0;
                if (element.diametro_interno != undefined && element.diametro_interno > this.maxDI) this.maxDI = element.diametro_interno;
                if (element.diametro_externo != undefined && +element.diametro_externo > this.maxDE) this.maxDE = +element.diametro_externo;
                if (element.alto != undefined && element.alto > this.maxL) this.maxL = element.alto;
                if (element.price != undefined && element.price > this.maxPrice) this.maxPrice = Math.round(element.price);
                element.images = [];
                if (element.extra_images != null) {
                    element.extra_images.forEach(index => {
                        img.src = index;
                        element.images.push(img);
                    })
                }
                img.src = element.product_imagesm;
                element.images.push(img);
                this.all_products.push(element);
            }
        });
    }

    private async addProductsPagination() {
        this.loading = true;
        this.data.page++;
        let currentPage = this.data.page;
        if (currentPage > 1) {
            currentPage--;
            this.data.offset = this.data.limit * currentPage;
        }
        await this.service.getProductsByUrl(this.data).subscribe(response => {
            console.log('response get products', response);
            this.addProducts(response);
            this.addItems();
        });
        this.loading = false;
    }

    filterbyCategories(products) {
        const uniqueCategory = [];

        products.filter((product) => {
            if (product.category_uid) {
                const index = uniqueCategory.indexOf(product.category_uid)
                if (index === -1) uniqueCategory.push(product.category_uid)
            }
        })
        this.categories = this.allCategories.filter(item => uniqueCategory.indexOf(item.id) > -1);
        return uniqueCategory
    }

    appliedFilter(event) {
        let index = this.brands.indexOf(event.target.value);  // checked and unchecked value
        console.log('index', index);
        if (event.target.checked)
            this.brands.push(event.target.value); // push in array cheked value
        else
            this.brands.splice(index, 1);  // removed in array unchecked value

        let brands = this.brands.length ? { brand: this.brands.join(",") } : { brand: null };
        console.log('result brands 2', this.brands);
        this.updateFilter();
    }

    // check if the item are selected
    checked(item) {
        if (this.brands.indexOf(item) != -1) {
            return true;
        }
    }
}
