import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpService} from "../../services/http.service";
import {isPlatformBrowser, ViewportScroller} from "@angular/common";
import {ProductService} from "../../shared/services/product.service";
import {FirestoreService} from "../../services/firestore.service";
import {Options} from "ng5-slider";

@Component({
    selector: 'app-classification',
    templateUrl: './classification.component.html',
    styleUrls: ['./classification.component.scss']
})
export class ClassificationComponent {

    public urlCategory: string       = '';
    public urlSubcategory: string    = '';
    public urlClassification: string = '';

    public grid: string = 'col-xl-3 col-md-6';
    public layoutView: string = 'grid-view';
    public all_products: any[] = [];
    public products: any[] = [];
    public brands: any[] = [];
    public colors: any[] = [];
    public size: any[] = [];
    public minPrice: number = 0;
    public maxPrice: number = 1200;
    public minDE: number = 0;
    public maxDE: number = 0;
    public minDI: number = 0;
    public maxDI: number = 0;
    public minL: number = 0;
    public maxL: number = 0;
    public tags: any[] = [];
    public category: string;
    public pageNo: number = 1;
    public paginate: any = {}; // Pagination use only
    public sortBy: string; // Sorting Order
    public mobileSidebar: boolean = false;
    public loader: boolean = true;
    public finished: boolean = false  // boolean when end of data is reached
    public addItemCount = 8;
    public url: string = '';
    public urlID: string = '';
    public loadGif: string = environment.loadGif;
    public showLoading: boolean = true;
    public infoClassification = {
        "id": '',
        "bannerClassification": environment.bannerDefault || 'assets/images/collection-banner.jpg',
        "nameClassification": '',
        "Classification_description": '',
        "Classification_highlights_description": '',
    };
    public sendBreadcrumbDynamic: any [] = [];
    public searchProduct: boolean = false;
    /**
     * added by leo 13-01-2022
     */
    public data = {
        'type': 3,
        'url': '',
        'offset': 1,
        'limit': 100,
        'page': 1,
    };
    public optionsDE: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsDI: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsL: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsPrice: Options = {
        floor: 0,
        ceil: 1e3,
    };
    public collapse: boolean = true;
    public isBrowser: boolean = false;
    public loading: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private route: ActivatedRoute,
        private router: Router,
        private service: HttpService,
        private viewScroller: ViewportScroller,
        public productService: ProductService,
        private firestore: FirestoreService,
    ) {
        if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
        this.urlCategory        = this.route.snapshot.paramMap.get('category');
        this.urlSubcategory     = this.route.snapshot.paramMap.get('subcategory');
        this.urlClassification  = this.route.snapshot.paramMap.get('classification');

        this.url = this.router.url;
        let arrayBreadcrumb = this.url.split('/');
        this.sendBreadcrumbDynamic = environment.breadcrumbDynamic(arrayBreadcrumb);
        this.firestore.getWhere('Classifications','url_classification',this.urlClassification).subscribe( res => {
            // console.log('response url_classification', res);
            if (res.length > 0) {
                this.infoClassification.id = res[0].id;
                this.infoClassification.nameClassification = res[0].classification_name;
                this.infoClassification.Classification_description = res[0].classification_description;
                this.infoClassification.Classification_highlights_description = res[0].classification_highlights_description;
                console.info('ID_clasificacion',res[0].ID_clasificacion);

                // this.firestore.getWhere('Products','ID_clasificacion', `${res[0].ID_clasificacion}`).subscribe( response => {
                this.data.url = this.urlClassification;
                this.service.getProductsByUrl(this.data).subscribe( response => {
                    // console.info('response getProductByClassification', response);
                    this.all_products = [];
                    this.addProducts(response);
                    this.showLoading = false;
                    this.addItems(666);
                })
            } else {
                this.searchProduct = true;
            }

        })
    }

    /**
     * edited by leo 13-01-2022
     */
    addItems(type = 0) {
        if (type > 0) {
            // this.finished = true;
            this.products = this.all_products.slice(0, this.addItemCount);
            this.sortByFilter('diametroExterior');
        }
        let newOptionDE: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDE) + ((10 / 100) * Number(this.maxDE))),
        };
        this.optionsDE = newOptionDE;

        let newOptionDI: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDI) + ((10 / 100) * Number(this.maxDI))),
        };
        this.optionsDI = newOptionDI;

        let newOptionL: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxL) + ((10 / 100) * Number(this.maxL))),
        };
        this.optionsL = newOptionL;

        let newOptionPrice: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxPrice) + ((10 / 100) * Number(this.maxPrice))),
        };
        this.optionsPrice = newOptionPrice;
        this.updateFilter();
    }

    // Infinite scroll
    public onScroll() {
        this.addProductsPagination();
    }

    // Append filter value to Url
    updateFilter(tags?: any) {
        this.products = this.all_products.filter(item =>
            item.price_tax_product >= this.minPrice && item.price_tax_product <= this.maxPrice &&
            item.diametro_externo >= this.minDE && item.diametro_externo <= this.maxDE &&
            item.diametro_interno >= this.minDI && item.diametro_interno <= this.maxDI &&
            item.alto >= this.minL && item.alto <= this.maxL
        );
    }

    // SortBy Filter
    sortByFilter(value) {
        this.sortBy = value;
        this.products = this.productService.sortProducts(this.products, this.sortBy);
    }

    // Remove Tag
    removeTag(tag) {

        this.brands = this.brands.filter(val => val !== tag);
        this.colors = this.colors.filter(val => val !== tag);
        this.size = this.size.filter(val => val !== tag);

        let params = {
            brand: this.brands.length ? this.brands.join(",") : null,
            color: this.colors.length ? this.colors.join(",") : null,
            size: this.size.length ? this.size.join(",") : null
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }

    // Clear Tags
    removeAllTags() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }


    // Change Grid Layout
    updateGridLayout(value: string) {
        this.grid = value;
    }

    // Change Layout View
    updateLayoutView(value: string) {
        this.layoutView = value;
        if (value == 'list-view')
            this.grid = 'col-lg-12';
        else
            this.grid = 'col-xl-3 col-md-6';
    }

    // Mobile sidebar
    toggleMobileSidebar() {
        this.mobileSidebar = !this.mobileSidebar;
    }

    priceFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minPrice: event.value, maxPrice: event.highValue};
            console.info('Price Filter', value);
            if (!type) {
                this.minPrice = value.minPrice;
                this.maxPrice = value.maxPrice;
            }
            // Price Filter
            this.products = this.all_products.filter(item => item.price >= this.minPrice && item.price <= this.maxPrice);
            if (!type) this.sortByFilter('low');
            this.updateFilter();
        }
    }

    diametroExteriorFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minDE: event.value, maxDE: event.highValue};
            console.info('diametroExterior Filter', value);
            if (!type) {
                this.minDE = value.minDE;
                this.maxDE = value.maxDE;
            }
            // diametroExterior Filter
            this.products = this.all_products.filter(item => item.diametro_externo >= this.minDE && item.diametro_externo <= this.maxDE);
            if (!type) this.sortByFilter('diametroExterior');
            this.updateFilter();
        }
    }

    diametroInteriorFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minDI: event.value, maxDI: event.highValue};
            console.info('diametroInterior Filter', value);
            if (!type) {
                this.minDI = value.minDI;
                this.maxDI = value.maxDI;
            }
            // diametroInterior Filter
            this.products = this.all_products.filter(item => item.diametro_interno >= this.minDI && item.diametro_interno <= this.maxDI);
            if (!type) this.sortByFilter('diametroInterior');
            this.updateFilter();
        }
    }

    largoFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minL: event.value, maxL: event.highValue};
            console.info('Altura Filter', value);
            if (!type) {
                this.minL = value.minL;
                this.maxL = value.maxL;
            }
            // largo Filter
            this.products = this.all_products.filter(item => item.alto >= this.minL && item.alto <= this.maxL);
            if (!type) this.sortByFilter('altura');
            this.updateFilter();
        }
    }

    addProducts(response) {
        response.products.forEach(element => {
            if (element.product_available && element.product_active && element.products_stock > 0 && element.price_tax_product > 0){
                let img = {
                    "src": '',
                    "alt": element.product_name,
                }
                element.title = element.product_name;
                element.description = element.specifications;
                element.brand = element.marca;
                element.sale = element.product_active;
                if (element.product_promote) element.discount = element.product_discount || element.discount;
                element.stock   = element.products_stock;
                element.price   = element.price_tax_product;
                element.diametro_interno = Number(element.diametro_interno);
                element.diametro_externo = Number(element.diametro_externo);
                element.alto = Number(element.alto);
                element.images = [];
                img.src = element.product_imagesm;
                if (element.diametro_interno != undefined && element.diametro_interno > this.maxDI) this.maxDI = element.diametro_interno;
                if (element.diametro_externo != undefined && +element.diametro_externo > this.maxDE) this.maxDE = +element.diametro_externo;
                if (element.alto != undefined && element.alto > this.maxL) this.maxL = element.alto;
                if (element.price != undefined && element.price > this.maxPrice) this.maxPrice = Math.round(element.price);
                element.images.push(img);
                if (element.extra_images != null) {
                    element.extra_images.forEach(index => {
                        img.src = index;
                        element.images.push(img);
                    })
                }
                this.all_products.push(element);
            }
        });
        this.addItems();
    }

    private async addProductsPagination() {
        this.loading = true;
        this.data.page++;
        let currentPage = this.data.page;
        if (currentPage > 1) {
            currentPage--;
            this.data.offset = this.data.limit * currentPage;
        }
        await this.service.getProductsByUrl(this.data).subscribe(response => {
            console.log('response get products', response);
            this.addProducts(response);
        });
        this.loading = false;
    }
}
