import {Component, OnInit, ViewChild} from '@angular/core';
import {addressCompany, Direction_User, User} from '../../../shared/classes/user';
import {Router} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {FirestoreService} from '../../../services/firestore.service';
import {ToastrService} from 'ngx-toastr';
import {HttpService} from '../../../services/http.service';
import {ViewportScroller} from '@angular/common';
import {element} from 'protractor';
import Swal from 'sweetalert2';
import {CommonService} from '../../../services/common.service';
import {environment} from '../../../../environments/environment';
import {routerLinkWithHref} from '@angular/core/schematics/migrations/router-link-with-href/util';
import {HttpClient} from '@angular/common/http';

interface Country {
    name: string;
    alpha2Code: string;
    flag: string;
    callingCodes: string[];
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    @ViewChild('form') targetElement: any;
    user = new User();
    addressCompany = new addressCompany();
    isLogged = false;
    showNewAddress = false;
    showBtnSave = true;
    address = new Direction_User();
    type: string;
    direction: string;
    zipCode: string;
    country: string;
    city: string;
    state: string;
    phone: string = '';
    public number_to_verify: string = '';
    public send_verification_code: boolean = false;
    public codeVerificationCheck: string = '';

    private TWILIO_SERVICE_ENDPOINT = environment.twilio.TWILIO_SERVICE_ENDPOINT;
    private TWILIO_SERVICE_VERIFICATION_CHECK = environment.twilio.TWILIO_SERVICE_VERIFICATION_CHECK;
    private TWILIO_AUTH_TOKEN = environment.twilio.TWILIO_AUTH_TOKEN;
    private TWILIO_ACCOUNT_SID = environment.twilio.TWILIO_ACCOUNT_SID;
    private MIN_PHONE_LENGTH = 8;
    selectedCountry: string = '503';
    // countries = [
    //     {name: 'El Salvador', code: '503'},
    //     {name: 'Guatemala', code: '502'},
    //     {name: 'Nicaragua', code: '505'},
    //     {name: 'Francia', code: '33' },
    //     {name: 'Estados Unidos', code: '1'},
    //     {name: 'España', code: '34'},
    //     {name: 'Perú', code: '51'},
    //     {name: 'México', code: '52'}
    // ];
    // countryPrefix = {
    //     '503': '503',
    //     '502': '502',
    //     '505': '505',
    //     '33': '33',
    //     '1': '1',
    //     '34': '34',
    //     '51': '51',
    //     '52': '52'
    // };
    // countryMask = {
    //     '503': '00000000',
    //     '502': '0000000000',
    //     '505': '00000000',
    //     '33': '0000000000',
    //     '1': '0000000000',
    //     '34': '0000000000',
    //     '51': '0000000000',
    //     '52': '0000000000'
    // };

    countries: Country[] = [];
    countryPrefix: { [code: string]: string } = {};
    countryMask: { [code: string]: string } = {};
    code: string = '';


    constructor(
        private router: Router,
        private auth: AuthService,
        private firestore: FirestoreService,
        private service: HttpService,
        private viewScroller: ViewportScroller,
        private toastrService: ToastrService,
        private commonService: CommonService,
        private http: HttpClient
    ) {
        this.auth.getUser2().then(async userLocal => {
            this.isLogged = true;
            console.log('userLocal: ', userLocal);
            this.user = userLocal;
            this.phone = (userLocal.phone && userLocal.phone['nationalNumber']) || '';
            console.log('phone', this.phone);
            // console.log('addressCompany', this.user.addressCompany);
            this.addressCompany = this.user.addressCompany == undefined ? this.addressCompany : this.user.addressCompany;
        }).catch(e => {
            console.log('catch ', e);
            this.router.navigate(['/pages/login']);
        });
    }

    ngOnInit(): void {
        console.log('addressCompany', this.addressCompany);
        // let test = document.querySelector('.card-header') as HTMLElement | null;
        // console.log('test', test);
        this.getDefaultCountry();
        this.getCountries();
    }

    getDefaultCountry() {
        this.http.get<any>('https://ipapi.co/json/').subscribe(
            response => {
                const userCountryCode = response.country_calling_code.replace('+', '');
                this.selectedCountry = userCountryCode;
                console.log('userCountryCode', response);
                this.phone = this.countryPrefix[userCountryCode];
                this.code = userCountryCode;
            }
        );
    }

    setDefaultCountry() {
        // aquí puedes implementar la lógica para detectar el país por defecto del usuario, utilizando la IP o cualquier otro método
        // por ahora, simplemente establecemos El Salvador como país por defecto
        // this.selectedCountry = 'SV';
    }

    getCountries() {
        this.http.get<Country[]>('https://restcountries.com/v2/all').subscribe(
            response => {
                console.log('countries', response);
                this.countries = response;
                this.countries.forEach(country => {
                    this.countryPrefix[country.alpha2Code] = '+' + country.callingCodes[0];
                    this.countryMask[country.alpha2Code] = '0'.repeat(country.callingCodes[0].length);
                });
                this.setDefaultCountry();
            },
            error => {
                console.log(error);
            }
        );
    }

    onCountryChange() {
        this.code = this.countryPrefix[this.selectedCountry];
    }

    updateUser() {
        // Comprobar si el nombre, apellido y número de teléfono son válidos
        if (this.user.firstName.length >= 3 && this.user.lastName.length >= 3 && this.user.verificate_phone == true) {
            this.showBtnSave = false;

            // Verificar si se ingresó información de dirección
            if (this.address.state != '' && this.address.direction != '' && this.address.zipCode != '' && this.address.country != '' && this.address.city != '') {
                // Agregar la dirección al arreglo de direcciones del usuario
                this.user.direction.push({
                    direction: this.address.direction,
                    city: this.address.city,
                    country: this.address.country,
                    is_default: false,
                    lat_long: undefined,
                    name_place: this.address.type,
                    state: this.address.state,
                    timestamp: undefined,
                    type: this.address.type,
                    zipCode: this.address.zipCode
                });
            }

            // Actualizar la dirección de la empresa del usuario
            this.user.addressCompany = {...this.addressCompany};

            // Mostrar una alerta de éxito
            this.commonService.alertModal('información', 1);

            // Actualizar el usuario en la base de datos
            this.service.updateUser({...this.user}, this.user.uid).subscribe(res => {
                this.commonService.alertModal('información', 2);
            });

            // Mostrar el botón de guardar de nuevo
            this.showBtnSave = true;
        } else {
            // Si los campos requeridos no están llenados, mostrar un mensaje de error
            let txt = 'Los siguientes campos son requeridos: ';
            if (this.user.firstName.length < 3 ) txt += 'Nombre debe contener al menos 3 caracteres, ';
            if (this.user.lastName.length < 3 ) txt += 'Apellido debe contener al menos 3 caracteres, ';
            if (!this.user.verificate_phone) txt += 'Número de télefono validado, ';
            txt = txt.slice(0, -2); // Eliminar la última coma y el espacio
            Swal.fire('Error', txt, 'info');
        }
    }


    changeStatus(index) {
        const status = this.user.direction[index].is_default;
        const txt = !status ? 'activated' : 'disabled';

        this.user.direction.forEach((element, i) => {
            if (!status && index != i) {
                element.is_default = false;
            }
        });

        this.user.direction[index].is_default = !status;
        this.toastrService.info('Address  ' + txt);
    }

    newAddress() {
        this.showNewAddress = !this.showNewAddress;
        // let height = this.targetElement.nativeElement.offsetHeight;
        setTimeout(() => {
            const top = this.targetElement.nativeElement.offsetHeight;
            this.viewScroller.scrollToPosition([0, top * 2]);
        }, 500);
    }

    sendVerificationCode(phone) {
        this.commonService.alertModal('');
        let numberString = phone.toString();
        console.log('this.user.phone', numberString.replaceAll(' ', ''));
        let tel = numberString.replaceAll(' ', '');
        console.log('tel .length', tel.length);
        let number = `+${this.code}${tel}`;

        if (tel.length < 8) {
            Swal.fire('error', 'Dear user, the number you have entered is incorrect, please verify', 'error');
        } else {
            const data = new URLSearchParams();
            data.append('To', number);
            data.append('Channel', 'sms');

            fetch(this.TWILIO_SERVICE_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${btoa(`${this.TWILIO_ACCOUNT_SID}:${this.TWILIO_AUTH_TOKEN}`)}`
                },
                body: data.toString()
            })
                .then(response => {
                    if (response.ok) {
                        Swal.fire('Success', 'El código ha sido enviado correctamente, por favor ingrese el código que ha sido enviado a su número, para poder validarlo en nuestro sistema.', 'success');
                        console.log('response', response);
                        this.send_verification_code = true;
                        this.number_to_verify = number;
                    } else {
                        Swal.fire('Error', 'se ha producido un error al enviar el código.', 'error');
                    }

                })
                .catch(error => {
                    console.error('error', error);
                });
        }

    }

    checkVerificationCode() {
        let code = this.codeVerificationCheck.toString();

        const body = new URLSearchParams();
        body.set('To', this.number_to_verify);
        body.set('Code', code);

        fetch(this.TWILIO_SERVICE_VERIFICATION_CHECK, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${btoa(`${this.TWILIO_ACCOUNT_SID}:${this.TWILIO_AUTH_TOKEN}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body.toString()
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.status == 'approved' && data.valid) {
                    let formattedNumber = this.commonService.formattedNumber(this.phone);
                    this.user.verificate_phone = true;
                    let message_post = {
                        verificate_phone: true,
                        date_verify: Date.now(),
                        user_uid_verify: this.user.uid,
                        name_user_verify: this.user.firstName,
                        verification_code: code,
                        phone: {
                            isoCode: 'sv',
                            internationalNumber: `+${this.code}${formattedNumber}`,
                            dialCode: `+${this.code}`,
                            nationalNumber: formattedNumber
                        }
                    };
                    this.service.updateUser(message_post, this.user.uid).subscribe(response => {
                        console.log('response update user', response);
                        this.commonService.alertModal('Número de teléfono', 2);
                    });
                } else {
                    this.commonService.alertModal('Número de teléfono', 3);
                }
            })
            .catch(error => {
                console.error(error);
                this.commonService.alertModal('Número de teléfono', 3);
            });
    }

}
