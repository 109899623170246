import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { ProductService } from "../../../shared/services/product.service";
import { breadcrumbDynamic, Product } from '../../../shared/classes/product';
import { environment } from "../../../../environments/environment";
import { HttpService } from "../../../services/http.service";
import { Options } from "ng5-slider";
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
    selector: 'app-collection-infinitescroll',
    templateUrl: './collection-infinitescroll.component.html',
    styleUrls: ['./collection-infinitescroll.component.scss']
})
export class CollectionInfinitescrollComponent implements OnInit {

    /**
     * edited
     */
    public grid: string = 'col-xl-3 col-md-6';
    public layoutView: string = 'grid-view';
    public all_products: any[] = [];
    public products: any[] = [];
    public brands: any[] = [];
    public colors: any[] = [];
    public size: any[] = [];
    public minPrice: number = 0;
    public maxPrice: number = 1;
    public minDE: number = 0;
    public maxDE: number = 0;
    public minDI: number = 0;
    public maxDI: number = 0;
    public minL: number = 0;
    public maxL: number = 0;
    public tags: any[] = [];
    public category: string;
    public pageNo: number = 1;
    public paginate: any = {}; // Pagination use only
    public sortBy: string; // Sorting Order
    public mobileSidebar: boolean = false;
    public loader: boolean = true;
    public finished: boolean = false  // boolean when end of data is reached
    public addItemCount = 8;
    public url: string = '';
    public urlID: string = '';
    public loadGif: string = environment.loadGif;
    public showLoading: boolean = true;
    public infoCategory = {
        "id": '',
        "bannerCategory": 'assets/images/collection-banner.jpg',
        "nameCategory": '',
        "category_description": '',
        "category_highlights_description": '',
    };
    // public data = {
    //     'id': '',
    //     'total_docs': 10e3,
    //     'num_page': 1,
    //     'limit': 30,
    //     'num_docs_page': 30,
    // };
    public data = {
        'type': 1,
        'url': '',
        'offset': 1,
        'limit': 100,
        'page': 1,
    };
    public subcategories: any[] = [];
    public breadcrumbDynamic: breadcrumbDynamic = {
        'name': 'products',
        'url': 'products',
    };
    public sendBreadcrumbDynamic: any[] = [];
    public categories: any[] = [];
    public optionsDE: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsDI: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsL: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsPrice: Options = {
        floor: 0,
        ceil: 1e3,
    };
    public collapse: boolean = true;
    public collapse_sizes: boolean = true;
    public collapse_colors: boolean = true;
    public collapse_conditions: boolean = true;
    public isBrowser: boolean = false;
    public loading: boolean = true;
    public sizesAndColors: any = {};
    public sizesSelected = [];
    public conditionsSelected = [];
    public colorsSelected = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private route: ActivatedRoute,
        private router: Router,
        private viewScroller: ViewportScroller,
        private service: HttpService,
        public productService: ProductService,
        private firestore: FirestoreService
    ) {

    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
        if (this.isDesiredUrlFormat()) {
            this.getCategories();
            this.init();
            this.getSettingsSizesAndColor();
        }
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && this.isDesiredUrlFormat()) {
                this.init();
            }
        })
    }

    getSettingsSizesAndColor() {
        this.firestore.getOneWatcher('Settings', 'SizesAndColors').subscribe(res => {
            if (resizeBy) {
                this.sizesAndColors = res;
            }
        })
    }

    isDesiredUrlFormat(): boolean {
        let url = this.router.url;
        // Verificar si la URL cumple con el formato deseado
        const segments = url.split('/');
        return segments.length === 3 && segments[1] === 'products';
    }

    addItems(type = 0) {
        if (type > 0) {
            // this.finished = true;
            this.products = this.all_products.slice(0, this.addItemCount);
            this.sortByFilter('diametroExterior');
        }
        let newOptionDE: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDE) + ((10 / 100) * Number(this.maxDE))),
        };
        this.optionsDE = newOptionDE;

        let newOptionDI: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDI) + ((10 / 100) * Number(this.maxDI))),
        };
        this.optionsDI = newOptionDI;

        let newOptionL: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxL) + ((10 / 100) * Number(this.maxL))),
        };
        this.optionsL = newOptionL;

        let newOptionPrice: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxPrice) + ((10 / 100) * Number(this.maxPrice))),
        };
        this.optionsPrice = newOptionPrice;
        this.updateFilter();
    }

    init() {
        this.products = [];
        this.sendBreadcrumbDynamic = [];
        this.finished = false;
        this.url = this.router.url;
        let arrayBreadcrumb = this.url.split('/');
        this.sendBreadcrumbDynamic = environment.breadcrumbDynamic(arrayBreadcrumb);
        // console.log('sendBreadcrumbDynamic', this.sendBreadcrumbDynamic);

        this.urlID = this.url.substring(this.url.lastIndexOf('/') + 1);

        // console.log('url', this.urlID);

        this.getCategoryByUrl();

    }

    // Infinite scroll
    public onScroll() {
        this.loading = true;
        this.addProductsPagination();
    }

    // Append filter value to Url
    updateFilter(tags?: any) {
        this.products = this.all_products.filter(item =>
            item.price_tax_product >= this.minPrice && item.price_tax_product <= this.maxPrice &&
            item.diametro_externo >= this.minDE && item.diametro_externo <= this.maxDE &&
            item.diametro_interno >= this.minDI && item.diametro_interno <= this.maxDI &&
            (this.sizesSelected.length === 0 || this.sizesSelected.includes(item.product_size_id)) &&
            (this.conditionsSelected.length === 0 || this.conditionsSelected.includes(item.product_condition_id)) &&
            (this.colorsSelected.length === 0 || this.colorsSelected.includes(item.product_color_id)) &&
            item.alto >= this.minL && item.alto <= this.maxL
        );
    }

    // SortBy Filter
    sortByFilter(value) {
        console.log('sortByFilter', value);
        this.sortBy = value;
        this.products = this.productService.sortProducts(this.products, this.sortBy);

        /*this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {sortBy: value ? value : null},
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });*/
    }

    // Remove Tag
    removeTag(tag) {

        this.brands = this.brands.filter(val => val !== tag);
        this.colors = this.colors.filter(val => val !== tag);
        this.size = this.size.filter(val => val !== tag);

        let params = {
            brand: this.brands.length ? this.brands.join(",") : null,
            color: this.colors.length ? this.colors.join(",") : null,
            size: this.size.length ? this.size.join(",") : null
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }

    // Clear Tags
    removeAllTags() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }

    // Change Grid Layout
    updateGridLayout(value: string) {
        this.grid = value;
    }

    // Change Layout View
    updateLayoutView(value: string) {
        this.layoutView = value;
        this.grid = value == 'list-view' ? 'col-lg-12' : 'col-xl-3 col-md-6';
    }


    // Mobile sidebar
    toggleMobileSidebar() {
        this.mobileSidebar = !this.mobileSidebar;
    }

    private async addProductsPagination() {
        this.loading = true;
        this.data.page++;
        let currentPage = this.data.page;
        if (currentPage > 1) {
            currentPage--;
            this.data.offset = this.data.limit * currentPage;
        }
        await this.service.getProductsByUrl(this.data).subscribe(response => {
            // console.log('response get products', response);
            this.addProducts(response);
            this.addItems();
        });
        this.loading = false;
    }

    /*getProductsByCategory(id) {
        console.table(this.data);
        this.data.id = id;
        this.service.getProductsByCategory(this.data).subscribe(res => {
            this.all_products = [];
            let products = [];
            console.log('response by products category', res);

            res.products.forEach(element => {
                if (element.product_available) {
                    let img = {
                        "src": '',
                        "alt": element.product_name,
                    }
                    element.title = element.product_name;
                    element.description = element.specifications;
                    element.brand = element.marca;
                    element.sale = element.product_active;
                    element.price = +element.product_price;
                    element.discount = element.product_discount;
                    element.stock = +element.products_stock;
                    if (element.diametro_interno != undefined && element.diametro_interno > this.maxDI ) this.maxDI = element.diametro_interno;
                    if (element.diametro_externo != undefined && +element.diametro_externo > this.maxDE ) this.maxDE = +element.diametro_externo;
                    if (element.alto != undefined && element.alto > this.maxL ) this.maxL = element.alto;
                    element.images = [];
                    if (element.extra_images != null) {
                        element.extra_images.forEach(index => {
                            img.src = index;
                            element.images.push(img);
                        })
                    } else {
                        img.src = element.product_imagesm;
                        element.images.push(img);
                    }
                    products.push(element);
                }
            });

            // All Products
            this.all_products = products;
            console.log('all_products', this.all_products);
            console.table(this.all_products);

            // Sorting Filter
            // this.all_products = this.productService.sortProducts(products, this.sortBy);

            // Price Filter
            // this.all_products = this.all_products.filter(item => item.price >= this.minPrice && item.price <= this.maxPrice)

            this.addItems(666);

            this.showLoading = false;
            this.finished = true;
        })
    }*/

    priceFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minPrice: event.value, maxPrice: event.highValue };
            console.info('Price Filter', value);
            if (!type) {
                this.minPrice = value.minPrice;
                this.maxPrice = value.maxPrice;
            }
            // Price Filter
            this.products = this.all_products.filter(item => item.price >= this.minPrice && item.price <= this.maxPrice);
            if (!type) this.sortByFilter('low');
            this.updateFilter();
        }
    }

    diametroExteriorFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minDE: event.value, maxDE: event.highValue };
            console.info('diametroExterior Filter', value);
            if (!type) {
                this.minDE = value.minDE;
                this.maxDE = value.maxDE;
            }
            // diametroExterior Filter
            this.products = this.all_products.filter(item => item.diametro_externo >= this.minDE && item.diametro_externo <= this.maxDE);
            if (!type) this.sortByFilter('diametroExterior');
            this.updateFilter();
        }
    }

    diametroInteriorFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minDI: event.value, maxDI: event.highValue };
            console.info('diametroInterior Filter', value);
            if (!type) {
                this.minDI = value.minDI;
                this.maxDI = value.maxDI;
            }
            // diametroInterior Filter
            this.products = this.all_products.filter(item => item.diametro_interno >= this.minDI && item.diametro_interno <= this.maxDI);
            if (!type) this.sortByFilter('diametroInterior');
            this.updateFilter();
        }
    }

    largoFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = { minL: event.value, maxL: event.highValue };
            console.info('Altura Filter', value);
            if (!type) {
                this.minL = value.minL;
                this.maxL = value.maxL;
            }
            // largo Filter
            this.products = this.all_products.filter(item => item.alto >= this.minL && item.alto <= this.maxL);
            if (!type) this.sortByFilter('altura');
            this.updateFilter();
        }
    }

    private addProducts(response) {
        this.finished = response.products?.length == 0;
        response.products?.forEach(element => {
            if (element.product_available && element.product_active && element.products_stock > 0 && element.price_tax_product > 0) {
                let img = {
                    "src": '',
                    "alt": element.product_name,
                }
                element.title = element.product_name;
                element.description = element.specifications;
                element.brand = element.marca;
                element.sale = element.product_active;
                if (element.product_promote) element.discount = element.product_discount || element.discount;
                element.stock = element.products_stock;
                element.price = element.price_tax_product;
                element.diametro_interno = Number(element.diametro_interno) || 0;
                element.diametro_externo = Number(element.diametro_externo) || 0;
                element.alto = Number(element.alto) || 0;
                // if (element.diametro_interno != undefined && element.diametro_interno > this.maxDI) this.maxDI = element.diametro_interno;
                // if (element.diametro_externo != undefined && +element.diametro_externo > this.maxDE) this.maxDE = +element.diametro_externo;
                // if (element.alto != undefined && element.alto > this.maxL) this.maxL = element.alto;
                if (element.price_tax_product != undefined && element.price_tax_product > this.maxPrice) this.maxPrice = Math.round(element.price_tax_product);
                element.images = [];
                if (element.extra_images != null) {
                    element.extra_images.forEach(index => {
                        img.src = index;
                        element.images.push(img);
                    })
                }
                img.src = element.product_imagesm;
                element.images.push(img);
                this.all_products.push(element);
            }
        });
    }

    getCategories() {
        this.service.getCategories().subscribe((res) => {
            res.forEach(element => {
                if (element.category_active && element.category_disabled) {
                    element.name = element.category_name;
                    element.url = element.url_category;
                    this.categories.push(element);
                }
            })
        });
    }

    getCategoryByUrl() {
        this.service.getCategoryByUrl(this.urlID).subscribe((res) => {
            // console.log('getCategoryByUrl', res);
            this.infoCategory.bannerCategory = res.category.image;
            this.infoCategory.category_description = res.category.category_description;
            this.infoCategory.category_highlights_description = res.category.category_highlights_description;
            this.infoCategory.nameCategory = res.category.name;
            this.infoCategory.id = res.category.id;
            if (res.subcategories.length > 0) this.subcategories = res.subcategories;
            this.data.url = this.urlID
            this.getProductsByUrl(this.data);
        });
    }

    getProductsByUrl(data) {
        this.service.getProductsByUrl(data).subscribe(response => {
            this.all_products = [];
            // console.log('response products by category', response)
            this.addProducts(response);
            this.showLoading = false;
            this.loading = false;
            this.addItems(666);
        });
    }

    isChecked(name, selectedArray) {
        return selectedArray.includes(name);
    }

    handleSelection(event, selectedArray) {
        const value = event.target.value;
        const index = selectedArray.indexOf(value);

        if (event.target.checked) {
            if (index === -1) selectedArray.push(value); // Agrega el valor si no existe
        } else {
            if (index !== -1) selectedArray.splice(index, 1); // Elimina el valor si existe
        }

        this.updateFilter();
    }

    handleSelectionColor(event, selectedArray) {
        const value = event.target.value;
        const index = selectedArray.indexOf(value);
        selectedArray.length = 0;
        
        if (event.target.checked) {
            if (index === -1) selectedArray.push(value); // Agrega el valor si no existe
        } else {
            if (index !== -1) selectedArray.splice(index, 1); // Elimina el valor si existe
        }

        this.updateFilter();
    }
}
