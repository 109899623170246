// This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',

    loading: {
        spinner: 'circles',
        duration: 3000
    },

    // PARAMETROS IANOS
    firebase: {
        apiKey: "AIzaSyDPw9J-pflR8khJ4PeFp-owHu-gUveYZLs",
        authDomain: "ianos-platform-dev.firebaseapp.com",
        databaseURL: "https://ianos-platform-dev-default-rtdb.firebaseio.com",
        projectId: "ianos-platform-dev",
        storageBucket: "ianos-platform-dev.appspot.com",
        messagingSenderId: "453907306516",
        appId: "1:453907306516:web:35d0a4df6afece46d82f01",
        measurementId: "G-1TY39ZT2WS"
    },
    googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
    urlBase: 'https://us-central1-ianos-platform-dev.cloudfunctions.net',
    apikey: '6a4747e52f7-0428b4969d52d-3fb2-f509dd16',
    urlBaseWompy: 'https://us-central1-ianos-platform-dev.cloudfunctions.net/Wompi',

    bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fimages%2Fstore%2Fianos-banner-default.png?alt=media&token=d5195338-f2cb-4408-9137-2695c3b734a4',

    // TWILIO CREDENCIALES IanOS APP
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/VerificationCheck`,
        TWILIO_AUTH_TOKEN: '97cd517dd6104fe0d1a9e6595510487f',
        TWILIO_ACCOUNT_SID: 'AC3cd8a9a45ccbb432105181d79f45b95d',
        TWILIO_SERVICE_TOKEN: 'VA5681a04e24d8bc289d6008f39af37744'
    },

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    countCharacter(string) {
        let limit = (this.width < 1560) ? 25 : 45;
        if (string.length > limit) {
            string = string.substring(0, limit) + '...';
        }
        return string;
    },

    breadcrumbDynamic(arrayBreadcrumb) {
        let sendBreadcrumbDynamic = [];
        arrayBreadcrumb.forEach(element => {
            if (element != '') {
                let info = {
                    'name': element.replaceAll('-', ' '),
                    'url': element,
                };
                sendBreadcrumbDynamic.push(info);
            }
        });
        return sendBreadcrumbDynamic;
    }
}
    ;

