import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, startWith, delay} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Product} from '../classes/product';
import {HttpService} from "../../services/http.service";
import {FirestoreService} from "../../services/firestore.service";
import {DataShoppingCart} from "../classes/order";
import {User} from "../classes/user";
import {AuthService} from "./auth.service";
import Swal from "sweetalert2";
import {DataSharingService} from '../../services/data-sharing.service';

const state = {
    products:   JSON.parse(localStorage['products'] || '[]'),
    wishlist:   JSON.parse(localStorage['wishlistItems'] || '[]'),
    compare:    JSON.parse(localStorage['compareItems'] || '[]'),
    cart:       JSON.parse(localStorage['cartItems'] || '[]'),
    orderId:    localStorage['orderId'] || '',
    user_uid:   localStorage['user_uid'] || '',
    cart_uid:   localStorage['cart_uid'] || '',
    DataShoppingCart: new DataShoppingCart(),
}

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    // public Currency = {name: 'Euro', currency: 'EUR', price: 1} // Default Currency
    public Currency = {name: 'Dollar', currency: 'USD', price: 1} // Default Currency
    public OpenCart: boolean = false;
    private orderId: any;
    public Products
    user = new User();

    constructor(
        private http: HttpClient,
        private service: HttpService,
        private firestore: FirestoreService,
        private auth: AuthService,
        private toastrService: ToastrService,
        private dataSharingService: DataSharingService,
    ) {
        this.socket();
    }

    /*
      ---------------------------------------------
      ---------------  Product  -------------------
      ---------------------------------------------
    */

    // Product
    private get products(): Observable<Product[]> {
        this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
        this.Products.subscribe(next => {
            localStorage['products'] = JSON.stringify(next)
        });
        return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    }

    // Get Products
    public get getProducts(): Observable<Product[]> {
        return this.products;
    }

    // Get Products By Slug
    public getProductBySlug(slug: string): Observable<Product> {
        return this.products.pipe(map(items => {
            return items.find((item: any) => {
                return item.title.replace(' ', '-') === slug;
            });
        }));
    }


    /*
      ---------------------------------------------
      ---------------  Wish List  -----------------
      ---------------------------------------------
    */

    // Get Wishlist Items
    public get wishlistItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.wishlist);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }

    // Add to Wishlist
    public addToWishlist(product): any {
        const wishlistItem = state.wishlist.find(item => item.id === product.id)
        if (!wishlistItem) {
            state.wishlist.push({
                ...product
            })
        }
        this.toastrService.success('Product has been added in wishlist.');
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true
    }

    // Remove Wishlist items
    public removeWishlistItem(product: Product): any {
        const index = state.wishlist.indexOf(product);
        state.wishlist.splice(index, 1);
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true
    }

    /*
      ---------------------------------------------
      -------------  Compare Product  -------------
      ---------------------------------------------
    */

    // Get Compare Items
    public get compareItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.compare);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }

    // Add to Compare
    public addToCompare(product): any {
        const compareItem = state.compare.find(item => item.id === product.id)
        if (!compareItem) {
            state.compare.push({
                ...product
            })
        }
        this.toastrService.success('Product has been added in compare.');
        localStorage.setItem("compareItems", JSON.stringify(state.compare));
        return true
    }

    // Remove Compare items
    public removeCompareItem(product: Product): any {
        const index = state.compare.indexOf(product);
        state.compare.splice(index, 1);
        localStorage.setItem("compareItems", JSON.stringify(state.compare));
        return true
    }

    /*
      ---------------------------------------------
      -----------------  Cart  --------------------
      ---------------------------------------------
    */

    // Get Cart Items
    public get cartItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.cart);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }

    // Add to Cart
    public addToCart(product): any {
        let cartItem = state.cart.find(item => item.id === product.id);
        let qty = product.quantity ? product.quantity : 1;
        let items = cartItem ? cartItem : product;
        let stock = this.calculateStockCounts(items, qty);

        if (!stock) return false

        if (cartItem) {
            cartItem.quantity += qty
        } else {
            state.cart.push({
                ...product,
                quantity: qty
            })
        }
        this.OpenCart = true; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        this.verifyDataShoppingCart();
        return true;
    }

    // Update Cart Quantity
    public updateCartQuantity(product: Product, quantity: number): Product | boolean {
        return state.cart.find((items, index) => {
            if (items.id === product.id) {
                const qty = state.cart[index].quantity + quantity
                const stock = this.calculateStockCounts(state.cart[index], quantity)
                if (qty !== 0 && stock) {
                    state.cart[index].quantity = qty
                }
                localStorage.setItem("cartItems", JSON.stringify(state.cart));
                this.verifyDataShoppingCart();
                return true
            }
        })
    }

    // Calculate Stock Counts
    public calculateStockCounts(product, quantity) {
        const qty = product.quantity + quantity
        // const qty = product.quantity;
        const stock = product.stock || product.products_stock;
        console.log('qty', qty);
        console.log('stock', stock);
        if (stock < qty || stock == 0) {
            this.toastrService.error('No se pueden añadir más artículos de los disponibles. En stock ' + stock + ' items.');
            return false
        }
        return true
    }

    // Remove Cart items
    public removeCartItem(product: Product): any {
        const index = state.cart.indexOf(product);
        state.cart.splice(index, 1);
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        this.verifyDataShoppingCart();
        return true
    }

    // Total amount
    public cartTotalAmount(): Observable<number> {
        return this.cartItems.pipe(map((product: Product[]) => {
            return product.reduce((prev, curr: Product) => {
                let price = curr.price;
                if (curr.discount > 0 && curr.product_promote) {
                    price = curr.price - (curr.price * curr.discount / 100)
                }
                // console.log('curr.price', curr.price)
                return (prev + price * curr.quantity) * this.Currency.price;
            }, 0);
        }));
    }

    /*
      ---------------------------------------------
      ------------  Filter Product  ---------------
      ---------------------------------------------
    */

    // Get Product Filter
    public filterProducts(filter: any): Observable<Product[]> {
        return this.products.pipe(map(product =>
            product.filter((item: Product) => {
                if (!filter.length) return true
                const Tags = filter.some((prev) => { // Match Tags
                    if (item.tags) {
                        if (item.tags.includes(prev)) {
                            return prev
                        }
                    }
                })
                return Tags
            })
        ));
    }

    // Sorting Filter
    public sortProducts(products: Product[], payload: string): any {

        if (payload === 'ascending') {
            return products.sort((a, b) => {
                if (a.id < b.id) {
                    return -1;
                } else if (a.id > b.id) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'a-z') {
            return products.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                } else if (a.title > b.title) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'z-a') {
            return products.sort((a, b) => {
                if (a.title > b.title) {
                    return -1;
                } else if (a.title < b.title) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'low') {
            return products.sort((a, b) => {
                if (a.price < b.price) {
                    return -1;
                } else if (a.price > b.price) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'high') {
            return products.sort((a, b) => {
                if (a.price > b.price) {
                    return -1;
                } else if (a.price < b.price) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'diametroExterior') {
            return products.sort((a, b) => {
                if (Number(a.diametro_externo) < Number(b.diametro_externo)) {
                    return -1;
                } else if (Number(a.diametro_externo) > Number(b.diametro_externo)) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'diametroInterior') {
            return products.sort((a, b) => {
                if (Number(a.diametro_interno) < Number(b.diametro_interno)) {
                    return -1;
                } else if (Number(a.diametro_interno) > Number(b.diametro_interno)) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'altura') {
            return products.sort((a, b) => {
                if (Number(a.alto) < Number(b.alto)) return -1;
                if (Number(a.alto) > Number(b.alto)) return 1;
                return 0;
            })
        }
    }

    /*
      ---------------------------------------------
      ------------- Product Pagination  -----------
      ---------------------------------------------
    */
    public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // Paginate Range
        let paginateRange = 3;

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange - 1) {
            startPage = 1;
            endPage = startPage + paginateRange - 1;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    public updateDataShoppingCart(){



        /*if (state.cart_uid == ''){
            this.service.saveDataShoppingCart( {...dataSend}).then( res => {
                // @ts-ignore
                if (res.success) localStorage.setItem('cart_uid', res.uid);
                console.info('save DataShoppingCart', res);
            }).catch( err => {
                console.error('error save DataShoppingCart', err);
            })
        } else {
            this.service.updateDataShoppingCartByCart({...dataSend},state.cart_uid).then( res => {
                console.info('update DataShoppingCart', res);
            }).catch( err => {
                console.error('error update DataShoppingCart', err);
            })
        }*/
    }

    public verifyDataShoppingCart(user_uid?){
        /*console.log('user_uid', user_uid);
        if (user_uid != undefined){
            console.log('entro user_uid', user_uid);
            state.user_uid = user_uid;
            localStorage.setItem('user_uid', user_uid);
            this.updateDataShoppingCartByCart();
        }
        state.orderId == '' ? this.newDataShoppingCart() : this.updateDataShoppingCartByCart();*/


        /*this.auth.getUser2().then(async userLocal => {
            console.log("userLocal: ", userLocal);
            this.user = userLocal;
            localStorage.setItem('user_uid', this.user.uid);
            state.user_uid = this.user.uid;
            this.service.getDataShoppingCartByUser(this.user.uid).subscribe( res => {
                console.log('getDataShoppingCartByUser', res);
                if (res.info?.success == undefined) {
                    this.newDataShoppingCart(this.user.uid);
                } else {
                    this.updateDataShoppingCartByCart(res);
                }
            })
        }).catch(e => {})*/

    }

    public newDataShoppingCart(user_uid?){
        let dataSend = this.DataShoppingCartByCart;
        console.info('DataShoppingCart', dataSend);
        this.service.newDataShoppingCart({...dataSend}).then( res => {
            console.info('response save DataShoppingCart', res);
            // @ts-ignore
            if (res.success) {
                // @ts-ignore
                console.log('res.uid', res.uid)
                // @ts-ignore
                localStorage.setItem('cart_uid', res.uid);
                // @ts-ignore
                state.cart_uid = res.uid;
            }
            console.info('new DataShoppingCart', dataSend);
        }).catch( err => {
            console.error('error create DataShoppingCart');
        })
    }

    public checkDataShoppingCart(){
        console.info('cart_uid', state.cart_uid);
        if (state.cart_uid != '') this.getDataShoppingCart();
    }

    public updateDataShoppingCartByCart(data){
        /*  let rawData     = data.shopping_cart[0];
          let itemsCloud   = rawData.items;
          let itemsLocal   = this.DataShoppingCartByCart.items;
          console.log('itemsCloud', itemsCloud)
          console.log('itemsLocal', itemsLocal)

          let mergeItems = [...new Set([{...itemsCloud}, {...itemsLocal}])];
          console.log('mergeData', mergeItems)
          rawData.items = mergeItems;
          console.log('rawData', rawData)*/

        /*this.firestore.update('DataShoppingCart',state.cart_uid, {...rawData}).then( res => {
            console.info('update DataShoppingCart', res);
        }).catch( err => {
            console.error('error update DataShoppingCart', err);
        })*/

        /*this.service.updateDataShoppingCartByCart({...dataSend},state.cart_uid).then( res => {
            console.info('update DataShoppingCart', res);
        }).catch( err => {
            console.error('error update DataShoppingCart', err);
        })*/
    }

    public getDataShoppingCart(){
        this.service.getDataShoppingCart(state.cart_uid).subscribe( res => {
            console.info('getDataShoppingCart ', res);
        })
    }

    get getTotal() {
        return this.cartTotalAmount();
    }

    get getOrderId() {
        if (state.orderId == undefined || state.orderId == ''){
            state.orderId = Date.now();
            localStorage.setItem('orderId', state.orderId);
        }
        return state.orderId;
    }

    get DataShoppingCartByCart(){
        let total       = 0;
        let items       = [];
        let order_uid   = state.orderId == '' ? Date.now() : state.orderId;
        localStorage.setItem("orderId", order_uid.toString());
        this.getTotal.subscribe( response => total = response );
        state.cart.forEach( element => {
            let cart = {
                'id':               element.id,
                'images':           element.images,
                'product_price':    element.product_price,
                'product_name':     element.product_name,
                'product_stock':    element.products_stock,
                'stock':            element.stock,
                'price':            element.price,
                'title':            element.title,
                'url_product':      element.url_product,
                'discount':         element.discount,
            }
            items.push(cart);
        })
        state.DataShoppingCart.items        = items;
        state.DataShoppingCart.uid          = order_uid;
        state.DataShoppingCart.total        = total;
        state.DataShoppingCart.user_uid     = state.user_uid;
        return state.DataShoppingCart;
    }

    public listProducts(data, isNew = false){
        let products = [];
        data.products.forEach( element => {
            if (element.product_available && element.product_active && element.products_stock > 0 && element.price_tax_product > 0){
                let img = {
                    "src": '',
                    "alt": element.product_name,
                }
                element.title           = element.product_name;
                element.description     = element.product_description || element.specifications;
                element.brand           = element.marca;
                element.sale            = element.product_active;
                // element.price           = element.product_price;
                element.price           = element.price_tax_product;
                element.new             = isNew;
                if (element.product_promote) element.discount = element.product_discount || element.discount;
                element.stock           = element.products_stock;
                element.images          = [];
                if (element.extra_images != null) {
                    element.extra_images.forEach(index => {
                        img.src = index;
                        element.images.push(img);
                    })
                }
                img.src = element.product_imagesm;
                element.images.push(img);
                products.push(element);
            }
        });
        return products;
    }

    updateCart() {
        console.log('before state.cart', state.cart);
        state.cart.forEach( (element, index) => {
            console.log('id', element.id);
            this.service.getOneProduct(element.id).subscribe( res => {
                console.log('response getOneProduct', res);
                state.cart[index].product_promote = res.product_promote;
                state.cart[index].product_name    = res.product_name;
                state.cart[index].title           = res.product_name;
                state.cart[index].product_price   = res.price_tax_product.toFixed(2);
                state.cart[index].price           = res.price_tax_product.toFixed(2);
                state.cart[index].discount        = res.discount;
                state.cart[index].price_tax_product_discount = res.price_tax_product_discount;
                state.cart[index].stock           = res.products_stock;
                console.log('res.products_stock', res.products_stock)
                if (res.products_stock < 1 || res.products_stock == undefined || !res.product_available || !res.product_active) {
                    Swal.fire(
                        'Estimado usuario',
                        `El siguiente articulo: ${res.product_name} ya no se encuentra disponible en nuestro inventario. Se ha eliminado de su carrito de compras`,
                        'info'
                    );
                    this.removeCartItem(state.cart[index]);
                }
            })
        })
        console.log('after state.cart', state.cart);
        // localStorage.setItem("cartItems", JSON.stringify(state.cart));
    }

    socket(){
        this.dataSharingService.currency.subscribe((valor: any) => {
            const mapAsString = localStorage.getItem('currency');

            const myMap = new Map(JSON.parse(mapAsString));

            const nameValue = myMap.get('name');
            const currencyValue = myMap.get('currency')
            this.Currency.name = nameValue.toString();
            this.Currency.currency = currencyValue.toString();
        });
    }

    saveLog(data) {
        return new Promise((resolve, reject) => {
            let sub_doc_uid = this.firestore.createID();
            this.firestore.saveSubcollection('Products', data.product_uid, 'Log', sub_doc_uid, data.info)
                .then(res => {
                    // Puedes realizar acciones adicionales en caso de éxito si es necesario.
                    resolve(res);
                })
                .catch(err => {
                    console.error('Error al guardar el registro en la subcolección Log:', err);
                    reject(err);
                });
        });
    }
}
