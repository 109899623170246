import {Component} from '@angular/core';
import {HttpHeaders, HttpResponse} from "@angular/common/http";

@Component({
    selector: 'app-descarga',
    templateUrl: './descarga.component.html',
    styleUrls: ['./descarga.component.scss']
})
export class DescargaComponent {

    ngOnInit(): void {
      this.generateSitemapXml();
    }

    generateSitemapXml(): HttpResponse<string> {
        const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      <url>
        <loc>https://midominio.com/page1</loc>
      </url>
      <url>
        <loc>https://midominio.com/page2</loc>
      </url>
      <!-- Agrega más URLs según sea necesario -->
    </urlset>`;

        const headers = new HttpHeaders({
            'Content-Type': 'application/xml',
        });

        return new HttpResponse({
            body: xmlContent,
            headers: headers,
        });
    }
}
