<app-breadcrumb [title]="'Resultado'" [breadcrumb]="'Resultado'"></app-breadcrumb>

<!-- section start -->
<section class="section-b-space pt-0 gym-product">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <!-- side-bar colleps block stat -->
                        <div *ngIf="resultSearch.length > 0" class="collection-filter-block">
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="categories.length">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Categoria</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="custom-control custom-checkbox collection-filter-checkbox"
                                            *ngFor="let category of categories; let i = index">
                                            <input type="checkbox" [value]="category.id"
                                                [checked]="checked(category.id)" [id]="category"
                                                (change)="appliedFilter($event)" class="custom-control-input" />
                                            <label class="custom-control-label" for="{{ category.id }}">{{
                                                category.category_name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- price filter start here -->
                            <div *ngIf="maxPrice > 0" class="collection-collapse-block border-0"
                                [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Precio</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2">
                                            <ng5-slider [(value)]="minPrice" [(highValue)]="maxPrice"
                                                [options]="optionsPrice" (userChangeEnd)="priceFilter($event)"
                                                *ngIf="isBrowser">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- price filter end here -->
                        </div>

                        <div class="collection-filter-block"
                            *ngIf="sizesAndColors && sizesAndColors.conditions && sizesAndColors.conditions.length > 0">
                            <!-- condition filter start here -->
                            <div class="collection-collapse-block border-0" [class.open]="collapse_conditions">
                                <h3 class="collapse-block-title" (click)="collapse_conditions = !collapse_conditions">
                                    Condiciones</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="custom-control custom-checkbox collection-filter-checkbox"
                                            *ngFor="let item of sizesAndColors.conditions; let i = index">
                                            <input type="checkbox" [value]="item.id"
                                                [checked]="isChecked(item.id, conditionsSelected)" [id]="item.id"
                                                (change)="handleSelection($event, conditionsSelected)"
                                                class="custom-control-input" />
                                            <label class="custom-control-label" for="{{ item.id }}">{{ item.name
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- condition filter end here -->
                        </div>

                        <div class="collection-filter-block"
                            *ngIf="sizesAndColors && sizesAndColors.sizes && sizesAndColors.sizes.length > 0">
                            <!-- size filter start here -->
                            <div class="collection-collapse-block border-0" [class.open]="collapse_sizes">
                                <h3 class="collapse-block-title" (click)="collapse_sizes = !collapse_sizes">Talla</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="custom-control custom-checkbox collection-filter-checkbox"
                                            *ngFor="let item of sizesAndColors.sizes; let i = index">
                                            <input type="checkbox" [value]="item.id"
                                                [checked]="isChecked(item.id, sizesSelected)" [id]="item.id"
                                                (change)="handleSelection($event, sizesSelected)"
                                                class="custom-control-input" />
                                            <label class="custom-control-label" for="{{ item.id }}">{{ item.name
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- size filter end here -->
                        </div>

                        <div class="collection-filter-block"
                            *ngIf="sizesAndColors && sizesAndColors.colors && sizesAndColors.colors.length > 0">
                            <!-- color filter start here -->
                            <div class="collection-collapse-block border-0" [class.open]="collapse_colors">
                                <h3 class="collapse-block-title" (click)="collapse_colors = !collapse_colors">Colores
                                </h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="custom-control custom-radio collection-filter-radio-horizontal">
                                            <div *ngFor="let item of sizesAndColors.colors; let i = index"
                                                class="custom-control custom-radio collection-filter-radio-item">
                                                <input type="radio" [value]="item.id"
                                                    [checked]="isChecked(item.id, colorsSelected)" id="color{{i}}"
                                                    name="color" (change)="handleSelectionColor($event, colorsSelected)"
                                                    class="custom-control-input radio-input"
                                                    [style.accent-color]="item.code" />
                                                <label for="color{{i}}" [style.background-color]="item.code"
                                                    class="radio-label {{(isChecked(item.id, colorsSelected)) ? '' : 'radio-item-label'}} "></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- size filter end here -->
                        </div>

                        <div class="collection-filter-block">
                            <!-- diámetro exterior filter start here -->
                            <div *ngIf="maxDE > 0" class="collection-collapse-block border-0" [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Diámetro exterior</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2">
                                            <ng5-slider [(value)]="minDE" [(highValue)]="maxDE" [options]="optionsDE"
                                                (userChangeEnd)="diametroExteriorFilter($event)" *ngIf="isBrowser">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- diámetro exterior filter end here -->

                            <!-- diámetro interior filter start here -->
                            <div *ngIf="maxDI > 0" class="collection-collapse-block border-0" [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Diámetro interior</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2">
                                            <ng5-slider [(value)]="minDI" [(highValue)]="maxDI" [options]="optionsDI"
                                                (userChangeEnd)="diametroInteriorFilter($event)" *ngIf="isBrowser">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- diámetro interior filter end here -->

                            <!-- altura filter start here -->
                            <div *ngIf="maxL > 0" class="collection-collapse-block border-0" [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Altura</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2">
                                            <ng5-slider [(value)]="minL" [(highValue)]="maxL" [options]="optionsL"
                                                (userChangeEnd)="largoFilter($event)" *ngIf="isBrowser">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- altura exterior filter end here -->
                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar single product slider start -->
                        <app-product-box-vertical-slider [title]="'Nuevos productos'">
                        </app-product-box-vertical-slider>
                        <!-- side-bar single product slider end -->
                    </div>
                </div>
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                            <span class="filter-btn btn btn-theme">
                                                <i class="fa fa-filter" aria-hidden="true"></i> Filter
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ul class="product-filter-tags">
                                    <li class="me-1" *ngFor="let tag of tags">
                                        <a href="javascript:void(0)" class="filter_tag">
                                            {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                                        </a>
                                    </li>
                                    <li class="clear_filter" *ngIf="tags.length > 0">
                                        <a href="javascript:void(0)" class="clear_filter" (click)="removeAllTags()">
                                            Clear all
                                        </a>
                                    </li>
                                </ul>
                                <div class="collection-product-wrapper" id="products">
                                    <app-grid [products]="resultSearch" [paginate]="paginate" [layoutView]="layoutView"
                                        [sortBy]="sortBy" (setGrid)="updateGridLayout($event)"
                                        (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-grid>
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row align-items-center">
                                            <div class="col-grid-box d-grid" [ngClass]="grid"
                                                *ngFor="let product of resultSearch">
                                                <div class="product-box d-grid align-content-center">
                                                    <app-product-box-one [product]="product"
                                                        [currency]="productService?.Currency" [thumbnail]="false"
                                                        [cartModal]="true">
                                                    </app-product-box-one>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found"
                                                *ngIf="!resultSearch.length && showLoading">
                                                <img [src]="loadGif" class="img-fluid mb-4">
                                                <p>Cargando contenido</p>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found"
                                                *ngIf="!resultSearch.length && !showLoading">
                                                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                                                <h3>¡Lo sentimos! No se han encontrado resultados para tu busqueda.
                                                </h3>
                                                <a [routerLink]="['/products']" class="btn btn-solid">seguir
                                                    comprando</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Infinite Scroll -->
                                    <!--<div infiniteScroll
                                         [infiniteScrollDistance]="2"
                                         [infiniteScrollThrottle]="1000"
                                         (scrolled)="onScroll()"
                                         *ngIf="products.length">
                                    </div>-->
                                    <!--<div class="product-infinitescroll">
                                        <div class="theme-paggination-block">
                                            <div class="container-fluid p-0">
                                                <div class="row">
                                                    <div class="text-center col-sm-12">
                                                        <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                                            <img src="assets/images/loader.gif" *ngIf="loading">
&lt;!&ndash;                                                            <div *ngIf="!loading && !finished" (click)="onScroll()" class="loading-more active my-3 cursor-pointer">Mostrar más</div>&ndash;&gt;
                                                            <div class="loading-more active" *ngIf="finished && products.length">No More Products</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->