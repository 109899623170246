<div *ngIf="!loader">
    <div class="img-wrapper">
        <div class="lable-block" *ngIf="product?.discount && product?.product_promote">
        <span class="lable3"> {{ product?.discount }}% </span>
        </div>
        <div class="front">
            <a [href]="['products'+product?.url_product]">
                <img
                        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
                        [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"
                        class="img-fluid lazy-loading"
                        alt="{{ product.images[0].alt }}"/>
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <a [href]="['products'+product?.url_product]">
                <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
            </a>
        </div>
        <ul class="product-thumb-list" *ngIf="thumbnail">
            <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
                <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
                    <img [lazyLoad]="image.src">
                </a>
            </li>
        </ul>
        <div class="cart-info cart-wrap">
            <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
               *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
               *ngIf="!cartModal">
                <i class="ti-shopping-cart"></i>
            </a>
            <a [hidden]="true" href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
                <i class="ti-heart" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a>
            <a [hidden]="true" href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
                <i class="ti-reload" aria-hidden="true"></i>
            </a>
        </div>
    </div>
    <div class="product-detail">
        <div>
            <bar-rating [hidden]="true" [rate]="5" [readOnly]="true"></bar-rating>
<!--            <a [routerLink]="['products'+product?.url_product]">-->
            <a [href]="['products'+product?.url_product]">
                <h6>{{ product?.title | titlecase }}</h6>
            </a>
            <p>{{ product?.description }}</p>
            <p class="d-block mb-0" >En inventario: {{ product?.stock }}</p>
            <ng-container *ngIf="product?.diametro_interno > 0 || product?.diametro_externo > 0 || product?.alto > 0">
                <p class="d-block">Medidas: DE: {{ product?.diametro_externo }} DI: {{ product?.diametro_interno }} H: {{ product?.alto }} </p>
            </ng-container>
            <h4>
                {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
                <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
            </h4>
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

