import {Component, Input, OnInit} from '@angular/core';
import {TeamSlider, TestimonialSlider} from '../../shared/data/slider';
import {HttpService} from "../../services/http.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
    id: string  = '';
    url: string = '';
    showError: boolean = false;
    content: string = '';
    title: string = '';


    public TeamSliderConfig: any = TeamSlider;
    public TestimonialSliderConfig: any = TestimonialSlider;
    testimonial: any [] = [];
    services: boolean = false;
    valores: any [] = [];
    news: any [] = [];
    timeline: any [] = [];
    showLoading: boolean = true;


    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private service: HttpService,
    ) {
        this.url = this.router.url;
        this.id = this.url.substring(this.url.lastIndexOf('/') + 1);
        this.service.getContentsWeb().subscribe((res) => {
            let verify = res.contents.filter(res => res.id == this.id);
            if (verify.length > 0){
                this.title       = verify[0].title == '' || verify[0].title == undefined ? this.id.replaceAll('-', ' ') : verify[0].title;
                this.content     = verify[0].content;
                this.testimonial = verify[0].testimonial != undefined ? verify[0].testimonial : [];
                this.services    = verify[0].services != undefined ? verify[0].services : false;
                this.valores     = verify[0].valores != undefined ? verify[0].valores : [];
                this.news        = verify[0].news != undefined ? verify[0].news : [];
                this.timeline    = verify[0].procesoCompra != undefined ? verify[0].procesoCompra : [];
            }
            this.showLoading = false;
            this.showError = verify.length == 0;
        });
    }

    ngOnInit(): void {
    }

}
