import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Options} from "ng5-slider";
import {ActivatedRoute, Router} from "@angular/router";
import {ViewportScroller} from "@angular/common";
import {HttpService} from "../../services/http.service";
import {ProductService} from "../../shared/services/product.service";
import {iterator} from "rxjs/internal/symbol/iterator";
import { FirestoreService } from 'src/app/services/firestore.service';


@Component({
    selector: 'app-promociones',
    templateUrl: './promociones.component.html',
    styleUrls: ['./promociones.component.scss']
})
export class PromocionesComponent {


    public grid: string = 'col-xl-3 col-md-6';
    public layoutView: string = 'grid-view';
    public all_products: any[] = [];
    public products: any[] = [];
    public brands: any[] = [];
    public allBrands: any[] = [];
    public percents: any[] = [];
    public colors: any[] = [];
    public size: any[] = [];
    public minPrice: number = 0;
    public maxPrice: number = 1;
    public minDE: number = 0;
    public maxDE: number = 0;
    public minDI: number = 0;
    public maxDI: number = 0;
    public minL: number = 0;
    public maxL: number = 0;
    public tags: any[] = [];
    public category: string;
    public paginate: any = {}; // Pagination use only
    public sortBy: string; // Sorting Order
    public mobileSidebar: boolean = false;
    public loader: boolean = true;
    public finished: boolean = false // boolean when end of data is reached
    public addItemCount = 8;
    public url: string = '';
    public loadGif: string = environment.loadGif;
    public showLoading: boolean = true;
    public data = {
        'offset': 1,
        'limit': 25,
        'page': 1,
        'num_page': 1,
        "order_by": 'doc_created_date',
        "order": 'asc',
        "total_docs_by_collection": 3e3,
    };


    public subcategories: any [] = [];
    public sendBreadcrumbDynamic: any [] = [];
    public categories: any [] = [];
    public optionsDE: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsDI: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsL: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsPrice: Options = {
        floor: 0,
        ceil: 1e3,
    };
    public collapse: boolean = true;
    public collapse_sizes: boolean = true;
    public collapse_colors: boolean = true;
    public collapse_conditions: boolean = true;
    public isBrowser: boolean = false;
    public loading: boolean = true;
    public categoriesSelected = [];
    public brandsSelected = [];
    public percentsSelected = [];
    public sizesAndColors: any = {};
    public sizesSelected = [];
    public conditionsSelected = [];
    public colorsSelected = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private route: ActivatedRoute,
        private router: Router,
        private viewScroller: ViewportScroller,
        private service: HttpService,
        public productService: ProductService,
        private firestore: FirestoreService
    ) {

    }


    ngOnInit(): void {
        // this.getCategories();
        this.init();
        this.getBrands();
        this.getSettingsSizesAndColor();
    }

    getSettingsSizesAndColor() {
        this.firestore.getOneWatcher('Settings', 'SizesAndColors').subscribe(res => {
            if (resizeBy) {
                this.sizesAndColors = res;
            }
        })
    }

    getBrands() {
        let orderField = 'brand_name';
        let orderBy = 'asc';
        console.time('brands');
        this.service.sendGET_brand(orderField, orderBy).subscribe((res) => {
            console.timeEnd('brands');
            res.brands.forEach(element => {
                this.allBrands.push({
                    id: element.id,
                    brand_name: element.brand_name,
                    ID_marca: element.ID_marca,
                });
            });
            this.verifyBrands();
        });
    }

    addItems(type = 0) {
        if (type > 0) {
        // this.finished = true;
            this.products = this.all_products.slice(0, this.addItemCount);
            this.sortByFilter('diametroExterior');
        }
        let newOptionDE: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDE) + ((10 / 100) * Number(this.maxDE))),
        };
        this.optionsDE = newOptionDE;


        let newOptionDI: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDI) + ((10 / 100) * Number(this.maxDI))),
        };
        this.optionsDI = newOptionDI;


        let newOptionL: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxL) + ((10 / 100) * Number(this.maxL))),
        };
        this.optionsL = newOptionL;


        let newOptionPrice: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxPrice) + ((10 / 100) * Number(this.maxPrice))),
        };
        this.optionsPrice = newOptionPrice;
        this.updateFilter();
    }


    init() {
        this.products = [];
        this.sendBreadcrumbDynamic = [];
        this.finished = false;


        this.getProducts(this.data);


    }


// Infinite scroll
    public onScroll() {
        this.loading = true;
        this.addProductsPagination();
    }


// Append filter value to Url
    updateFilter(tags?: any) {
        this.products = this.all_products.filter(item => {
            return (
                item.price_tax_product >= this.minPrice &&
                item.price_tax_product <= this.maxPrice &&
                (this.categoriesSelected.length === 0 || this.categoriesSelected.includes(item.category_name)) &&
                (this.brandsSelected.length === 0 || this.brandsSelected.includes(item.brand_name)) &&
                (this.sizesSelected.length === 0 || this.sizesSelected.includes(item.product_size_id)) &&
                (this.conditionsSelected.length === 0 || this.conditionsSelected.includes(item.product_condition_id)) &&
                (this.colorsSelected.length === 0 || this.colorsSelected.includes(item.product_color_id)) &&
                (this.percentsSelected.length === 0 || this.percentsSelected.includes(item.discount.toString()))
            );
        });
        console.log('this.percentsSelected',this.percentsSelected)
    }



    // SortBy Filter
    sortByFilter(value) {
        // console.log('sortByFilter', value);
        this.sortBy = value;
        this.products = this.productService.sortProducts(this.products, this.sortBy);
    }


    // Remove Tag
    removeTag(tag) {
    }


    // Clear Tags
    removeAllTags() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }


    // Change Grid Layout
    updateGridLayout(value: string) {
        this.grid = value;
    }


    // Change Layout View
    updateLayoutView(value: string) {
        this.layoutView = value;
        this.grid = value == 'list-view' ? 'col-lg-12' : 'col-xl-3 col-md-6';
    }


    // Mobile sidebar
    toggleMobileSidebar() {
        this.mobileSidebar = !this.mobileSidebar;
    }


    private async addProductsPagination() {
        this.loading = true;
        this.data.page++;
        this.data.num_page++;
        let currentPage = this.data.page;
        if (currentPage > 1) {
            currentPage--;
            this.data.offset = this.data.limit * currentPage;
        }
        console.time('api');
        this.service.getProductsByOrder(this.data).subscribe(res => {
            console.timeEnd('api');
            this.addProducts(res);
            this.addItems();
        });


        this.loading = false;
    }


    priceFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minPrice: event.value, maxPrice: event.highValue};
            if (!type) {
                this.minPrice = value.minPrice;
                this.maxPrice = value.maxPrice;
            }
            // Price Filter
            this.products = this.all_products.filter(item => item.price >= this.minPrice && item.price <= this.maxPrice);
            if (!type) this.sortByFilter('low');
            this.updateFilter();
        }
    }


    private addProducts(response) {
        this.finished = response.products?.length == 0;
        response.products?.forEach(element => {
            if (element.product_available && element.product_active && element.products_stock > 0 && element.price_tax_product > 0) {
                let img = {
                    "src": '',
                    "alt": element.product_name,
                }
                element.title = element.product_name;
                element.description = element.specifications;
                element.brand = element.marca;
                element.sale = element.product_active;
                if (element.product_promote) element.discount = element.product_discount || element.discount;
                element.stock = element.products_stock;
                element.price = element.price_tax_product;
                element.diametro_interno = Number(element.diametro_interno) || 0;
                element.diametro_externo = Number(element.diametro_externo) || 0;
                element.alto = Number(element.alto) || 0;
                if (element.price_tax_product != undefined && element.price_tax_product > this.maxPrice) this.maxPrice = Math.round(element.price_tax_product);
                element.images = [];
                if (element.extra_images != null) {
                    element.extra_images.forEach(index => {
                        img.src = index;
                        element.images.push(img);
                    })
                }
                this.addItemIfNotExists(this.categories, element.category_name);
                if (this.allBrands.length > 0) this.addItemIfNotExists(this.brands, element.brand_name);
                this.addItemIfNotExists(this.percents, element.discount);
                img.src = element.product_imagesm;
                element.images.push(img);
                this.all_products.push(element);
            }
        });
    }


    verifyBrands() {
        this.all_products.forEach(item => {
            let brand_uid = typeof item.brand_uid === 'number' ? item.brand_uid : item.brand_uid;
            let verify = this.allBrands.find(element => element.ID_marca == brand_uid || element.id == brand_uid);

            if (verify) {
                item.brand_uid = verify.id;
                item.brand_name = verify.brand_name;
                this.addItemIfNotExists(this.brands, verify.brand_name);
            } else {
                this.addItemIfNotExists(this.brands, item.brand_name);
            }
        });
    }


    addItemIfNotExists(array, name) {
        let index = array.findIndex(item => item.name === name);

        let data = {
            name: name
        };

        if (index === -1) {
            // El elemento no existe en el array, por lo que lo agregamos
            array.push(data);
        }
    }


    getCategories() {
        this.service.getCategories().subscribe((res) => {
            res.forEach(element => {
                if (element.category_active && element.category_disabled) {
                    element.name = element.category_name;
                    element.url = element.url_category;
                    this.categories.push(element);
                }
            })
        });
    }


    getProducts(data) {
        console.time('api 2');
        this.service.getProductsByOrder(this.data).subscribe(response => {
            console.timeEnd('api 2');
            this.all_products = [];
            this.addProducts(response);
            this.showLoading = false;
            this.loading = false;
            this.addItems(666);
        });
    }


    isChecked(name, selectedArray) {
        return selectedArray.includes(name);
    }

    handleSelection(event, selectedArray) {
        const value = event.target.value;
        const index = selectedArray.indexOf(value);

        if (event.target.checked) {
            if (index === -1) selectedArray.push(value); // Agrega el valor si no existe
        } else {
            if (index !== -1) selectedArray.splice(index, 1); // Elimina el valor si existe
        }

        this.updateFilter();
    }

    handleSelectionColor(event, selectedArray) {
        const value = event.target.value;
        const index = selectedArray.indexOf(value);
        selectedArray.length = 0;
        
        if (event.target.checked) {
            if (index === -1) selectedArray.push(value); // Agrega el valor si no existe
        } else {
            if (index !== -1) selectedArray.splice(index, 1); // Elimina el valor si existe
        }

        this.updateFilter();
    }
}

